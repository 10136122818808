window.resources = window.resources || {};
window.resources["strings.js"] = {
  "AddMedia": "Add Media",
  "AddPresentUrl": "Add media via a {0} URL:",
  "AddYouTubeOrPresentUrl": "Add media via a YouTube or {0} URL:",
  "Author_By": "by",
  "Author_Created": "Created",
  "CopyBadge": "Copy Badge",
  "All": "All",
  "Analytics": "Analytics",
  "Analytics_AllViewers": "All Viewers",
  "Analytics_Completion": "Completion",
  "Analytics_CompletionRate": "Completion Rate",
  "Analytics_FilterByGroup": "Filter by Group",
  "Analytics_Loading": "Loading analytics...",
  "Analytics_ViewedRate": "Viewed Rate",
  "Analytics_ViewedRateNotAvailable": "Global viewed rate is unavailable for images, please select a group.",
  "Analytics_Viewer": "Viewer",
  "Analytics_Views": "Views",
  "ChangeMedia": "Change Media",
  "Completed": "Completed",
  "MakeCopy": "Make a Copy",
  "Edit": "Edit",
  "EditExisting": "Edit Existing",
  "Error": "Error",
  "ErrorLoadingAnalytics": "Analytics for this media is currently unavailable. Please try again later.",
  "HeadBack": "Feel free to head back to",
  "LinkMedia": "Link Media",
  "Loading": "Loading...",
  "LoadingMedia": "Loading Media...",
  "Media": "Media",
  "MediaBeingProcessedTitle": "This media is being processed.",
  "MediaBeingProcessedSubtitle": "Processing time is dependent on a number of factors including the length of the video.",
  "MissingTheMark": "We seem to be missing the mark right now.",
  "SetupLtiAccount_ViewBag_Title": "Setup your account",
  "SetupLtiAccount_MainText": "Connect and verify your account",
  "SetupLtiAccount_FriendlyText": "<p>\n         Accessing this content requires you to connect and verify your account with TechSmith.com using your organization’s email domain.\n         <br/>\n         Ex: youremail@yourschool.com\n         <br/>\n         Contact your administrator if you have any questions.\n      </p>",
  "MyMedia": "My Media",
  "None": "None",
  "NotViewed": "Not Viewed",
  "PageNotFound": "Page Not Found",
  "Processing": "Processing",
  "ProcessingEllipsis": "Processing...",
  "Search": "Search",
  "SearchResultsMatching": "Search results matching:",
  "SearchUnavailable": "Search Currently Unavailable",
  "SearchUnavailableFriendlyText": "Feel free to head back to <a href=\"/\">{0}</a>.",
  "Partial": "Partial",
  "Privacy": "Privacy",
  "Quizzing": "Quizzing",
  "ServerError_FriendlyMessage": "<p>\n         Sorry, something unexpected happened.\n         <br/>\n         Please try refreshing the page.\n      </p>\n      <p>If that doesn't work, please let us know on our <a href=\"https://support.techsmith.com/home\" target=\"_blank\" rel=\"noopener noreferrer nofollow\" >support site</a>.</p>",
  "ServerError_ViewBag_Title": "Server Error",
  "SignIn": "Sign In",
  "Thumbnail": "Thumbnail",
  "UsersMedia": "{{user.displayName}}'s media",
  "ValidationError": "Oops, that's not a valid link.",
  "Views": "Views",
  "Viewed": "Viewed",
  "Whoops": "Whoops",
  "YouTubeBadge": "YouTube Badge",
  "Copy": "Copy",
  "Delete": "Delete",
  "Download": "Download",
  "SetThumbnail": "Set Thumbnail",
  "SetThumbnailHover": "Set current frame as thumbnail",
  "GetALink": "Get a Link",
  "GetEmbedCode": "Get Embed Code",
  "ShareToGroup": "Share to Group",
  "Share": "Share",
  "AddedBy_Added": "Added",
  "AddedBy_by": "By",
  "AddedBy_TechSmithUser": "Created by {0} user",
  "AddedBy_YouTubeUser": "Created by YouTube user",
  "DescriptionPlaceholder": "Add description ({0} character limit) - Markdown accepted.",
  "MediaDescriptionEditHelp": "Press ENTER to save description. Press SHIFT + ENTER to insert line break.",
  "Close": "Close",
  "ErrorLoadingQuiz": "Quizzing for this media is currently unavailable. Please try again later.",
  "Quizzing_Loading": "Loading quiz information...",
  "Quizzing_NoQuiz_NotOwner": "There is currently no quiz associated with this {0}.",
  "Quizzing_NoQuiz_Owner": "There is no quiz associated with this {0}. Create some questions to get started!",
  "Quizzing_ViewResults": "View Results",
  "Remove": "Remove",
  "Cancel": "Cancel",
  "Done": "Done",
  "EmbedCode": "Embed Code",
  "EmbedCode_Header": "Here's the Embed Code",
  "EmbedCode_Text": "Copy and paste this HTML to embed this media item into your web page.",
  "EmbedCode_TooSmall": "The dimensions you entered are smaller than the recommended size. Width should be at least 320 pixels.",
  "GetLink_Header": "Share Folder",
  "MediaLink": "Media Link",
  "GetLink_Text": "Copy and paste this URL to share media with viewers.",
  "GroupShareInstructions_CreateGroup": "Create Group",
  "GroupShareInstructions_Header": "Share to Group",
  "GroupShareInstructions_RemoveAll": "Remove from all",
  "GroupShareInstructions_ShareAll": "Share to all",
  "Height": "Height",
  "Pixel_px": "px",
  "Width": "Width",
  "GroupNameEmptyError": "Group name cannot be empty.",
  "GroupNameNotString": "Group name is not a string.",
  "GroupNameTooLong": "Collection names are limited to {0} characters.",
  "Confirm": "Confirm",
  "Untitled": "(untitled)",
  "ViewAllSearchResults": "View all search results",
  "DescriptionLimitError": "Description is limited to {0} characters.",
  "Global": "Global",
  "GlobalUserCount": "out of {0} started {1}",
  "Viewer": "Viewer",
  "AllRightsReserved": "All rights reserved.",
  "ContactAdministrator": "Contact Administrator",
  "Help": "Help",
  "PrivacyPolicy": "Privacy Policy",
  "TermsOfUse": "Terms of Use",
  "ToggleSearch": "Toggle Search",
  "GoAddQuestions": "Add Questions",
  "UnkownUser": "Unknown User",
  "Back": "Back",
  "HasNotLoggedIn": "(Has not logged in)",
  "Next": "Next",
  "Unavailable": "(unavailable)",
  "WatchOwnerStep1": "Click Edit to record or replace narration, apply a theme for polish, or update a video project.",
  "CopyingMedia": "Copying media...",
  "CopyMediaRedirect": "A copy of this media has been saved to your library. Would you like to view it now?",
  "GroupShareInstructions_NoGroups": "Looks like you're not a group manager. Create a new group to share this {0}.",
  "GroupShareInstructions_Text": "Share this {0} with your groups.  Check to share, uncheck to remove.",
  "NoThanks": "No Thanks",
  "Video": "video",
  "Viewers": "Viewers",
  "Created": "Created:",
  "NoneCompleted": "None<br />Completed",
  "PartiallyCompleted": "Partially<br />Completed",
  "QuizEdit": "Edit Quizzing",
  "PrivacyIcon": "Privacy Icon",
  "PrivacyTab_Public": "Only people with the link",
  "PrivacyTab_Organization": "Only people at {0}",
  "PrivacyTab_Organization_WithLink": "Only people at {0} with the link",
  "PrivacyTab_OnlyYou_SingleUser": "Only you",
  "Copied": "Copied!",
  "ThumbnailSetError": "We're sorry, we couldn't set the thumbnail. Please try again.",
  "ThumbnailSetMessage": "Thumbnail will be set momentarily",
  "FieldIsRequired_Name": "This field is required. Type a name and press Enter or Return.",
  "EntryTooLong": "This entry is too long to display. Please use less than {0} characters",
  "DeleteConfirmation_Header": "\"{0}\" will be permanently deleted.",
  "DeleteConfirmation_Title": "Delete Media",
  "Create": "Create",
  "CreateAGroup": "Create New Group",
  "GroupName": "Group Name",
  "GroupPrivacyLevel": "Group Privacy Level:",
  "GroupPrivacy_InviteHeader": "By invite only",
  "GroupPrivacy_InviteText": "Only those invited by the group's managers can see and join this group.",
  "GroupPrivacy_LinkHeader": "Only those with the link",
  "GroupPrivacy_LinkText": "Not searchable. Only those with the link can find and join this group.",
  "GroupPrivacy_OpenHeader": "Open to your organization",
  "GroupPrivacy_OpenText": "All logged in users can search for, navigate to, and join this group.",
  "DeletingContent": "Deleting content...",
  "UnableToDeleteContent": "We're sorry, we couldn't delete this file. Please try again.",
  "UpdateExisting_Confirm": "Yes, Update Existing",
  "UpdateExisting_Header": "Update existing video?",
  "UpdateExisting_Text": "Updating the video will keep the original link. However, any view analytics, quizzes, and quiz results associated with this video will be lost.",
  "Group": "Group",
  "Member": "member",
  "ChangePermission_Header": "Changing the media privacy to this setting will result in the following:",
  "ChangePermission_Bullet1": "The assignee will lose all assigned privileges.",
  "ChangePermission_Bullet3": "The media will no longer be accessible from groups it was in.",
  "ChangePermission_Bullet4": "The media will no longer be accessible from collections it was in.",
  "ChangePermission_Title": "Set Privacy to 'Only you'?",
  "Members": "members",
  "QuizResults": "Quiz Results",
  "UntitledVideo": "Untitled Video",
  "UntitledImage": "Untitled Image",
  "Copy_MacInstructions": "Press ⌘-{0} to {1}",
  "Copy_TapToCopy": "Tap selection to copy",
  "Copy_WinInstructions": "Press Ctrl-{0} to {1}",
  "MissingMedia": "Missing Media",
  "MissingMedia_Text": "The creator of this course hasn't added any content.\n         <br/>\n         Please check back later.",
  "ConnectionIssue_ViewBag_Title": "LTI Connection Issue",
  "SwitchedUser_AuthError": "There was an authentication error, probably because someone\n         else had previously logged into the LMS on this computer.",
  "SwitchedUser_RefreshPage": "Refreshing this page should fix everything.",
  "EarthToLMS": "Earth to LMS...",
  "SignInFailed_Text": "There is a communication or authentication problem between your LMS and {0}.\n         <br/>\n         Please contact your LMS administrator.",
  "AccessDenied": "Access Denied",
  "NotAuthorized": "Not Authorized",
  "PrivateMedia": "Private Media",
  "ThisMediaIsSetToPrivate": "This media is set to private.",
  "RemoveQuizError": "We're sorry, we couldn't remove your quiz. Please try again.",
  "RemoveQuiz_Header": "Are you sure you want to remove quiz {0}?",
  "RemoveQuiz_Text": "This quiz will be permanently removed and will erase existing user responses for this quiz.",
  "RemoveQuiz_Title": "Remove quiz",
  "RemovingQuiz": "Removing your quiz...",
  "SuccessfullyRemovedQuiz": "Successfully removed your quiz.",
  "EditQuiz": "Edit Quiz",
  "LaunchExternalTool_Title": "Launch External Tool",
  "ShowQuizResults": "View Results",
  "Opening": "Opening",
  "WebConnector_Login_Accept": "Sign In",
  "WebConnector_Login_Body": "You need to be signed in to {0} to continue.",
  "WebConnector_Login_Title": "Not Signed in to {0}",
  "HangOn": "Hang on",
  "Captioning_Transcription_GenerateCaptions": "Generate Captions",
  "Captions_Transcription_EditManually": "Add Captions Manually",
  "Or": "or",
  "Captioning_Transcription_HelpText": "Generated captions may contain some errors. We'll send an email when they're ready for you to review and edit. This feature will become more accurate with use.",
  "Captioning_Transcription_InProgressMessage": "We're working on your captions and will send an email when they're ready for review.",
  "Captioning_Transcription_StopTranscriptionButtonText": "Stop Generating Captions",
  "Captioning_CaptionsExist_Message": "Click Edit Captions if you'd like to make changes. Don't forget to Publish when you're done!",
  "Captioning_EditCaptions": "Edit Captions",
  "Captioning_Transcription_FailedMessage": "We're sorry, but a technical problem occurred on our end while generating captions. You can restart the process by selecting 'Generate Captions' or you can manually add captions by selecting 'Edit Captions.'",
  "CopyError": "An error has occurred when trying to copy your media. Please check your connection and try again.",
  "MediaCopied": "Media successfully copied!",
  "MediaCopyFailed": "Media copy failed",
  "CopyingMediaToYourLibrary": "Copying media to your library",
  "MediaBeingProcessedHelpfulText": "For more information, visit the help page <a href=\"https://support.techsmith.com/hc/en-us/articles/203830258\" target=\"_blank\" rel=\"noopener noreferrer nofollow\" >here.</a>",
  "MediaBeingUploadedHelpfulText": "For more information, visit the help page <a href=\"https://support.techsmith.com/hc/en-us/articles/203830258\" target=\"_blank\" rel=\"noopener noreferrer nofollow\" >here.</a>",
  "MediaBeingUploadedSubtitle": "If this is a shared machine, you can log out. The upload will continue as long as the machine is on and connected to the Internet.",
  "MediaBeingUploadedTitle": "This media is being uploaded.",
  "MediaFailedSubtitle": "To learn more, <a href=\"https://support.techsmith.com/hc/en-us\">contact support.</a>",
  "MediaFailedTitle": "There seems to be a problem with this video.",
  "MediaNotReadyNonAdminSubtitle": "Please check back soon.",
  "MediaStatusStepProcessing": "Step 3 of 3",
  "MediaStatusStepUpload": "Step 2 of 3",
  "Date": "Date",
  "Title": "Title",
  "Acknowledgements": "Acknowledgments",
  "LibraryBatchAddMediaToGroupError": "Share to Group failed",
  "LibraryBatchAddMediaToGroupProcessing": "Sharing Media to {0}",
  "LibraryBatchAddMediaToGroupSuccess": "Share to Group complete!",
  "RemoveFromGroup": "Remove From Group",
  "DeleteMedia": "Delete Media",
  "Move": "Move",
  "MoveContentFailure": "Failed to move content",
  "MoveContentSuccess": "Content moved successfully!",
  "MovingContent": "Moving Content to {0}",
  "RootFolder": "Root",
  "SubFolder": "Subfolder",
  "SubFolders": "Subfolders",
  "RootFolderName": "My Library",
  "DeleteFolderMessage": "\"{0}\" and all of its contents will be permanently deleted.",
  "DeleteFolderTitle": "Delete Folder",
  "ErrorRenamingFolder": "Whoops! Something went wrong renaming the folder.",
  "FolderNameEmptyError": "Folder name cannot be empty.",
  "FolderNameNotString": "Folder name is not a string.",
  "FolderNameTooLong": "Folder names are limited to 100 characters.",
  "Location": "Location:",
  "LocationWithoutColon": "Location",
  "MyLibrary": "My Library",
  "Folders": "Folders",
  "ErrorCreatingFolder": "Whoops! Something went wrong creating the folder.",
  "CannotCreateSubfolderErrorMessage": "You cannot create a subfolder within a subfolder.",
  "CannotCreateSubfolderErrorTitle": "Cannot Create Folder",
  "ResetData": "Reset Data",
  "ResetData_Body_1": "All viewer data including views, quiz submissions, and conversations will be reset for this media.",
  "Reset": "Reset",
  "ResettingData": "Resetting Data",
  "DownloadData": "Download Data",
  "RemoveQuiz": "Remove Quiz",
  "ResetDataFailed": "Failed to reset data. Please try again.",
  "ResetDataSuccess": "Data for media reset successfully!",
  "CsvDownloadError": "Whoops, something went wrong downloading your data.  Please try again.",
  "StartAt": "Start at current time",
  "AddUsersHeader": "Add Users",
  "AddUsersSubmitError": "There was an error sending the request to add the users ({0}).",
  "AddUsersSubmitted": "The request to add users has been submitted. You will receive an email when the process is complete.",
  "AddUsersSubmittedEmailDisabled": "The request to add users has been successfully submitted.",
  "ErrorProcessingRequest": "Error processing request",
  "DownloadViewingAnalytics": "Download Viewing Analytics",
  "PreparingDownload": "Preparing download ... do not navigate away from this page.",
  "AddedUsers": "Added Users",
  "Administrator": "Administrator",
  "AllUsers": "All users",
  "Blocked": "Blocked",
  "BlockedUsers": "Blocked Users",
  "Creator": "Creator",
  "Creators": "Creators",
  "User": "User",
  "Admin": "Admin",
  "Block": "Block",
  "BlockUsers_Header": "Block User(s)",
  "BlockUsers_Text": "Are you sure you wish to block these user(s)?  They will be denied all access to the system and any existing assigned roles will be removed.",
  "CaptionAdmin": "Caption Admin",
  "CaptionAdmins": "Caption Admins",
  "ErrorLoadingUsers": "Whoops! Something went wrong loading users.",
  "ErrorSavingChanges": "Whoops! We had a problem saving some of the changes.",
  "Unblock": "Unblock",
  "UnblockUsers_Header": "Unblock user(s)",
  "UnblockUsers_Text": "Are you sure you wish to unblock these user(s)?  They will regain access to the site with default permissions.",
  "UsersWereBlocked": "users were blocked.",
  "UsersWereUnblocked": "users were unblocked.",
  "SiteAdmin": "Site Admin",
  "SiteAdmins": "Site Admins",
  "Captioning_DeleteCaptionTrackConfirmWidget_Title": "Delete Caption Track",
  "Captioning_DeleteCaptionTrackConfirmWidget_Body": "This will permanently delete all caption tracks on this video.",
  "Captioning_Transcription_Failure": "An error occurred. If the problem persists, please contact your administrator.",
  "CaptionAdminLabel": "Caption Admin:",
  "CreatorDescription": "Can upload and manage their own media. Can create and manage groups.",
  "CreatorLabel": "Creator:",
  "SiteAdminDescription": "Can manage all media, users, user roles, groups, closed captions, and site settings.",
  "SiteAdminLabel": "Site Admin:",
  "ViewerDescription": "Can view media, take quizzes, and join groups.",
  "ViewerLabel": "Viewer:",
  "CaptioningInProgress_LastSavedAt": "Captions last saved at:",
  "CaptioningInProgress_LastSavedBy": "Captions last saved by:",
  "CaptioningInProgress_Message": "Another user has already begun editing captions for this video. By selecting \"Continue\" you will become the new caption editor.",
  "CaptioningInProgress_Title": "Captioning in Progress",
  "Continue": "Continue",
  "Activity_Captioning_Filter_InProgress": "In Progress",
  "Activity_Captioning_Filter_Published": "Published",
  "Unknown": "Unknown",
  "Activity_Captioning_Filter_Uncaptioned": "Uncaptioned",
  "EditCaptions": "Edit Captions",
  "Activity_Captioning_Actions_Add_Or_Change": "Add/Change Assignee",
  "Activity_Captioning_Actions_Remove": "Remove Assignee",
  "Captioning_RemoveAssignee_Dialog_Body": "Assigned users will lose caption editing privileges for the selected media.",
  "Activity_Captioning_Filter_Assigned_To_Me": "Assigned to Me",
  "AddingUserandSendingEmail": "Adding user and sending e-mail ...",
  "AddingUserSuccess": "Assignee Added",
  "Captions_AddAssignee_Error": "Whoops, something went wrong while adding an assignee",
  "Captions_AddAssignee_NonexistentUser": "User not found. Unable to add assignee.",
  "CaptioningAssignmentModal_BodyBottom": "Note: Current assignees will lose editing capabilities",
  "CaptioningAssignmentModal_BodyTop": "Enter the email address of the user you want to give caption editing privileges to for the selected media.",
  "CaptioningAssignmentModal_Header": "Add/Change Assignee",
  "CaptioningAssignmentModal_Placeholder": "Enter Email Address...",
  "PleaseEnterValidEmail": "Please enter a valid email address (e.g. \"someone@domain.com\")",
  "Captions_RemoveAssignee_Error": "Whoops, something went wrong while removing assignee",
  "Captions_RemoveAssignee_Success": "Assignee Removed",
  "AddAssignee": "Add Assignee",
  "Apply": "Apply",
  "CaptioningAssignmentModalWatch_BodyBottom": "Generate speech-to-text captions",
  "CaptioningAssignmentModalWatch_BodyTop": "Enter the email address of the user you want to give caption editing privileges to.",
  "Captioning_Transcription_InProgressMessage_WithActivity": "We're working on your captions.  Visit your <a href=\"/accessibility#/captioning?filter=Transcribing\">Captioning Activity</a> to see the status.",
  "ErrorChangingRole": "There was an error trying to change the role.",
  "ErrorLoadingGroupMembers": "There was an error loading the group members.",
  "ErrorRemoveLastManager": "You cannot remove the last manager.",
  "ErrorRemovingMember": "There was an error trying to remove the member from the group.",
  "ContextMenu": "Context Menu",
  "GetLink": "Get Link",
  "Folder": "Folder",
  "FolderName": "Folder Name:",
  "Rename": "Rename",
  "Submit": "Submit",
  "ContentTypeYouTube": "video/youtube",
  "GetFolderLink_Text": "Link to Folder:",
  "GroupMedia": "Media",
  "GroupMembers": "Members",
  "UserGroupCount": "Groups",
  "UserMediaCount": "Media",
  "ListView": "List",
  "TileView": "Tile",
  "Role": "Role",
  "GroupRole_Contributor": "Contributor",
  "GroupRole_Manager": "Manager",
  "GroupRole_Member": "Member",
  "GroupRole_NotMember": "Not A Member",
  "GroupRole_Participant": "Participant",
  "UsersMediaReturnLinkText": "{0}'s media",
  "CaptionAssignPrivateMediaFailure": "You cannot add an assignee to a private video",
  "Leave": "Leave",
  "Upload_Continue": "Continue Upload",
  "Upload_Leave_Confirm_Body": "Closing this dialog will stop the upload.",
  "Upload_Leave_Confirm_Title": "Do you want to cancel your upload?",
  "Canceled": "Canceled",
  "Upload_Invalid_File_Type": "This file does not meet video or image requirements.",
  "ActiveUsers": "Active users",
  "Adding": "Adding...",
  "AddingQuiz": "Adding quiz...",
  "Administrator_EnterEmailAddress": "Enter the email address of a user to make them a site administrator.",
  "AllPrivacy": "All",
  "AnalyticsAddQuizAction": "Add Quiz",
  "AnalyticsContentCategory": "Content",
  "AuthenticationMethodUpdate": "Your authentication method has been updated.",
  "BandwidthUsage": "Bandwidth usage",
  "Blocked_EnterEmailAddress": "Enter the email address of a user you want to block from the system.",
  "ChangeOwner_Title": "Change Owner",
  "ChangePermission_Text": "You will need to manually re-add it to each group if you change your mind later.",
  "ConfirmAuthUpdate_Domain": "All users who signed up using {0} will be required to validate their account by email before logging in",
  "ConfirmAuthUpdate_IdentityProvider": "All users who signed up using {0} will no longer be able to log in until they validate with an organizational account.",
  "ConfirmMediaDelete_Header": "Delete {0}",
  "ConfirmMediaDelete_Text": "{0} will be permanently deleted.",
  "ConfirmRevoke_DomainEmail": "Are you sure you want to revoke access to domain email accounts?",
  "ConfirmRevoke_IdentityProvider": "Are you sure you want to revoke access to your organizational accounts?",
  "CopyErrorPrivate": "This content is private. The owner must change the privacy settings before you can add it.",
  "CreatingGroup": "Creating Group",
  "Creator_EnterEmailAddress": "Enter the email address of a user to make them a media creator.",
  "CustomerMessageHideDetails": "Hide details",
  "CustomerMessageViewDetails": "View details",
  "DefaultContentPrivacyUpdatedMessage": "Your default content privacy level has been updated.",
  "DeleteConfirmation_Text": "This {0} will be permanently deleted.",
  "DeleteMedia_Header": "Delete Media",
  "DeleteMedia_Text": "Are you sure?  The media will no longer be available to any users.",
  "DeleteMultipleUserMedia_Text": "Do you want to permanently delete this media?  This action cannot be undone.",
  "DeleteUserMedia_Header": "Delete User Media",
  "DeleteUserMedia_Text": "Do you want to permanently delete {0}'s media?  This action cannot be undone.",
  "DeleteUsersSubmitted": "The request to delete users has been successfully submitted. You will receive an email at {0} when it has completed.",
  "DeleteUsersSubmittedEmailDisabled": "The request to delete users has been successfully submitted.",
  "DeleteUsers_CannotDeleteCurrentUser": "The current user ({0}) cannot be in the list of users to be deleted.",
  "DeleteUsers_CannotDeleteProtectedUserRole": "Users with protected roles cannot be deleted ({0}).",
  "DeleteUsers_Header": "Delete Users",
  "DeleteUsers_MediaGroupsMM": "{0} pieces of media will be deleted and the following groups will be disbanded:",
  "DeleteUsers_MediaGroupsMS": "{0} pieces of media will be deleted and the following group will be disbanded:",
  "DeleteUsers_MediaGroupsMZ": "{0} pieces of media will be deleted and no groups will be disbanded.",
  "DeleteUsers_MediaGroupsSM": "1 piece of media will be deleted and the following groups will be disbanded:",
  "DeleteUsers_MediaGroupsSS": "1 piece of media will be deleted and the following group will be disbanded:",
  "DeleteUsers_MediaGroupsSZ": "1 piece of media will be deleted and no groups will be disbanded.",
  "DeleteUsers_MediaGroupsZM": "No media will deleted and the following groups will be disbanded:",
  "DeleteUsers_MediaGroupsZS": "No media will be deleted and the following group will be disbanded:",
  "DeleteUsers_MediaGroupsZZ": "No media will be deleted and no groups will be disbanded.",
  "DeletingGroup": "Deleting group...",
  "DomainEmail": "a domain email",
  "EmailAddressAlreadyAdded": "This email address has already been added to the system.",
  "EnterGroupName": "Please enter a Group Name",
  "ErrorAddingDuplicateMediaToGroup": "You cannot add the same media to a group twice.",
  "ErrorAddingNewDomain": "Whoops, something went wrong adding the new allowed email domain. Please try again.",
  "ErrorAddingMediaToGroup": "Whoops! Something went wrong adding this media, please check the link and try again.",
  "ErrorChangingCopyRestriction": "Whoops, something went wrong changing the copy restriction. Please try again.",
  "ErrorChangingCreationRestriction": "Whoops, something went wrong changing the creation restriction. Please try again.",
  "ErrorChangingPublicVideoRestriction": "Whoops, something went wrong changing the Public Video restriction. Please try again.",
  "ErrorChangingShareRestriction": "Whoops, something went wrong changing the share restriction. Please try again.",
  "ErrorChangingYouTubeRestriction": "Whoops, something went wrong changing the YouTube restriction. Please try again.",
  "ErrorCreatingGroup": "Whoops, something went wrong creating the group.",
  "ErrorCreatingSupportTicket": "There was an error creating your support ticket.",
  "ErrorCreatingUser": "Whoops! Something went wrong creating user.",
  "ErrorDeleteUsersNoValidEmails": "There was an error processing the request to delete users.  No valid user emails.",
  "ErrorDeleteUsersPrepare": "No users were deleted.",
  "ErrorDeleteUsersSubmit": "There was an error sending the request to delete the users ({0}).",
  "ErrorDeletingGroup": "There was an error trying to delete the group.",
  "ErrorDeletingMedia": "There was an error deleting the {0}",
  "ErrorDeletingUsersMedia": "Whoops! Something went wrong deleting <strong>{0}</strong>'s media.",
  "ErrorDismissingCustomerMessage": "Whoops, something went wrong with dismissing the message",
  "ErrorGeneratingNewLTIKey": "Whoops, something went wrong generating the new LTI consumer key. Please try again.",
  "ErrorImportingYouTube": "Whoops! Something went wrong importing the YouTube link, please check the link and try again.",
  "ErrorLoadingBillingCycle": "Whoops! Something went wrong loading billing cycle information.",
  "ErrorLoadingGroupCount": "Whoops! Something went wrong loading group count.",
  "ErrorLoadingVideoCount": "Whoops! Something went wrong loading video count.",
  "ErrorLTINameAlreadyUsed": "That name has already been used. Please specify a different name for this LTI integration.",
  "ErrorManageMediaChangeOwner": "There was an error transferring ownership of one or more pieces of media to {0}.",
  "ErrorManageMediaDeleteContent": "There was an error deleting one or more pieces of media for the selected user(s).",
  "ErrorRemovingEmailDomain": "Whoops, something went wrong removing the allowed email domain. Please try again.",
  "ErrorRemovingMedia": "Whoops! We had a problem removing the media you requested.",
  "ErrorRemovingMediaFromGroup": "There was an error removing the {0} from the group.",
  "ErrorRetrievingListOfGroups": "Error occurred while retrieving list of groups",
  "ErrorRevokingLTIKey": "Whoops, something went wrong revoking the LTI consumer key. Please try again.",
  "ErrorSavingAccessControlChoice": "Whoops, something went wrong saving your access control choice. Please try it again.",
  "ErrorSavingDefaultContentPrivacyChoice": "Whoops, something went wrong saving your default content privacy level choice. Please try it again.",
  "ErrorSendingGroupInvitation": "Whoops, something went wrong sending the invitation to <b>{0}</b>. Please try again.",
  "ErrorSettingIdentityProvider": "Whoops, something went wrong while setting your Identity Provider.  Please try again.",
  "GroupDelete_Header": "Delete Group",
  "GroupDelete_Text": "“{0}” will be permanently removed from your library.",
  "GroupNameSaved": "Group name saved.",
  "GroupPermissionsUpdated": "Group default permission updated.",
  "GroupPrivacyUpdated": "Group privacy updated.",
  "GroupsStep1": "Groups are an effective way to deliver content to a specific set of people.",
  "GroupsStep2": "Any groups you manage or belong to will be listed here.",
  "GroupsStep3": "You can even create your own group and add others to collaborate with.",
  "GroupsStep4": "Looking for a specific group? Try searching here.",
  "Guest_EnterEmailAddress": "Enter the email address of a user you want to log into the system.",
  "Image": "image",
  "LibraryStep1": "Welcome to your personal Library. This is where you'll find your media.",
  "LibraryStep2": "Use the Create button to add media to your Library.",
  "LibraryStep3": "To connect with others and share media, visit the My Groups tab.",
  "ManageMediaChangeOwnerSuccess": "The ownership of all media for the selected user(s) has been transferred to {0}.",
  "ManageMediaDeleteContentSuccess": "All media for the selected user(s) has been deleted.",
  "ManageMedia_Title": "Manage media",
  "EmailCharLimitExceeded": "A maximum of {0} characters can be entered.",
  "EmailCountExceeded": "A maximum of {0} emails can be entered.",
  "HasImproperlyFormattedEmail": "There is 1 improperly formatted email.",
  "HasImproperlyFormattedEmails": "There are {0} improperly formatted emails.",
  "MassDeleteUsers_Header": "Delete users",
  "NoProperlyFormattedEmails": "There are no properly formatted emails.",
  "MediaChangeOwnerSuccess": "The ownership of the selected media has been transferred to {0}.",
  "MediaChangeOwner_CannotTransferToOriginator": "Media ownership cannot be transferred to the originating user ({0}).",
  "MediaRemoved": "Media removed.",
  "MediaRemovedFromGroup": "The {0} will no longer be shared on the group page.",
  "MediaViews": "Media views",
  "MustSupplyValidEmailAddress": "You must supply a valid email address (e.g. \"bob.smith@example.com\")",
  "NewAccounts": "New accounts",
  "NewGroup": "New Group",
  "NewMedia": "New media",
  "NotLinkToPresentMedia": "This is not a link to {0} media. Please check the URL.",
  "Ok": "Ok",
  "OneMomentPlease": "One moment please...",
  "OrganizationalAccount": "an organizational account",
  "OrganizationPrivacy": "Organization",
  "PleaseEnterCompleteYouTubeLink": "Please enter a complete YouTube link",
  "PleaseEnterLink": "Please enter a link",
  "PleaseEnterYouTubeLink": "Please enter a YouTube link",
  "PreparingRequest": "Preparing request...",
  "PrivatePrivacy": "Private",
  "Project": "project",
  "ProjectModified_Confirm": "Yes, View Updates",
  "ProjectModified_Header": "Hot off the presses!",
  "ProjectModified_Text": "The author of this video is currently making updates. Would you like to use the most recent version?",
  "PublicPrivacy": "Public",
  "RecipientAlreadyMemberOfGroup": "<strong>{0}</strong> is already a member of this group.",
  "RecipientWasAddedToGroup": "<strong>{0}</strong> was added to the group and sent a notification email.",
  "RemoveMediaFromGroup": "Remove {0} from group?",
  "RemoveMediaFromGroup_Body": "{0} will no longer be shared on the group page.",
  "RemoveMember_Confirmation": "User removed.",
  "RemoveMember_Header": "Are you sure you want to remove this member from the group?",
  "RemoveMember_Text": "The member will no longer be able to view any group information.",
  "Revoke": "Revoke",
  "RevokeAccessToAccounts": "Revoke access",
  "RevokeEmailDomainAccess": "All users who log in with emails from this domain will no longer be able to access the site.",
  "RevokeIdentityProviderAccess": "All users who log in with emails from this domain will no longer be able to authenticate with your identity provider.",
  "RevokeLTIIntegration_Header": "Are you sure you want to revoke access for this integration?",
  "RevokeLTIIntegration_Text": "Users will no longer be able to sign in using this LTI integration.",
  "RoleChangesSaved": "Role changes saved",
  "RoleChangesSavedForUsers": "Role changes saved for <strong>{0}</strong> users.",
  "ShareGroupTimeout": "We're sorry, we couldn't add group \\\"{0}\\\". Please try again.",
  "SomethingWentWrong": "Whoops! Something went wrong.",
  "SomethingWentWrongRefreshBrowser": "Whoops! Something went wrong. Try refreshing your browser.",
  "SpecifyNameForLTI": "Please specify a name for this LTI integration.",
  "SuccessfullyAddedQuiz": "Successfully added your new quiz...",
  "SuccessRevokingLTIKey": "The LTI integration was successfully revoked.",
  "TicketHasBeenCreated": "Ticket has been successfully created.",
  "ToAddMedia": "To add to your media...",
  "UnableToAddQuiz": "We're sorry, we couldn't add a quiz. Please try again.",
  "UnableToUpdateDescription": "Failed to update {0} description.",
  "UnableToUpdateMediaLevel": "We're sorry, we couldn't update {0} access level. Please try again.",
  "UnlistedPrivacy": "Organization (Unlisted)",
  "UserAlreadyExists": "User already exists",
  "UserAlreadyHasRole": "User already has that role.",
  "UserEmailNotFound": "A user with this email address cannot be found.",
  "UsersMediaWasDeleted": "<strong>{0}</strong>'s media was deleted.",
  "UserWasAdded": "User was added.",
  "VideoErrorGeneric_Message": "We were unable to add this media. Check your Internet connection and try again. If you still receive this error, <a href=\\\"{0}\\\">contact your administrator.</a>",
  "VideoErrorGeneric_Title": "We couldn't add this media.",
  "YouTubeConnectionError": "The connection to YouTube failed.",
  "YouTubeVideoNotFound": "The YouTube video was not found. It may have been removed or marked private by its creator.",
  "Upload_MediaValidationError": "This file is not valid. Violations [{0}].",
  "Upload_GeneralError": "There was an error uploading this file.",
  "Nothing": "Nothing",
  "Privacy_AnyoneLinkToolTip": "Anyone with the link can see",
  "Privacy_AnyoneOrganizationToolTip": "Anyone from your organization can see",
  "Privacy_AnyoneOrganization_WithLinkToolTip": "Anyone from your organization with the link can see",
  "Privacy_OnlyYouToolTip": "Only you can see",
  "PrivateSelectionLabel": "{0} Private",
  "ProcessingSelectionLabel": "{0} Processing",
  "PrivateAndProcessingSelectionLabel": "{0} Private, {0} Processing",
  "SelectionLabel": "{0} Selected",
  "SelectionLabelWithDetails": "{0} Selected ({1})",
  "AddFolder": "Add Folder",
  "OpenFolder": "Open Folder",
  "ConfirmAddLtiPrivateMediaBody": "The privacy setting of this media prevents others from seeing it.",
  "ConfirmAddLtiPrivateMediaButtonText": "Add Anyway",
  "ConfirmAddLtiPrivateMediaTitle": "Media is only viewable by you",
  "UserLibrary": "User Library",
  "MyGroups": "My Groups",
  "ChangeOwner_Body": "Enter the email address of the new owner.",
  "ErrorChangeOwner": "There was an error changing the owner of the {0}",
  "CollapseFoldersSection": "Collapse Folders Section",
  "ExpandFoldersSection": "Expand Folders Section",
  "EmailInput_NonexistentUser": "Email address is not associated with a {0} user.",
  "Downloading": "Downloading...",
  "OperatingSystemNotSupported": "This operating system is not supported by TechSmith Capture.",
  "ErrorLaunchingRecorder": "There was an error launching TechSmith Capture. Please try again.",
  "Editing_Tab": "Edit Video",
  "Editing_DestructiveDialog_ProcessingBody": "You will be redirected to the editor when copy completes.",
  "EditingTab_EditButton": "Edit Original",
  "EditingTab_Message": "Choose to either create and edit a copy of this video or edit the original.  Don't forget to publish when you're done!",
  "UploadMedia": "Upload Media",
  "Upload_Cancel": "Cancel Upload",
  "Upload_ChooseFiles": "Choose Files",
  "Upload_DropFilesToBegin": "Drop files to begin upload",
  "Upload_MediaInstructions_Multiple": "Drag and drop files here, or",
  "Upload_MediaRequirements": "<a href=\"{0}\" target=\"_blank\" rel=\"noopener noreferrer nofollow\" >View Media Requirements</a>",
  "Upload_SelectFilesToUpload": "Select Files to Upload",
  "ReplaceMedia": "Replace Media",
  "SwapMedia": "Swap Media",
  "Upload_MediaInstructions_Single": "Drag and drop a file here, or",
  "Upload_ReplaceMedia_BodyText": "Select a video or image file from your computer to replace your original media.  All captions, quizzes, comments, analytics, and your original media will be lost.",
  "Upload_SelectFileToUpload": "Select a File to Upload",
  "BrowserNotSupported_Body": "Video editing is currently not supported using Internet Explorer. To get the best experience we recommend using the latest version of Chrome, Firefox, or Safari.",
  "BrowserNotSupported_Title": "Browser not supported",
  "CopyMediaSuffix": "(Copy)",
  "EditingTab_EditCopyButton": "Edit a Copy",
  "EmbedIframeTitle": "TechSmith SmartPlayer Container",
  "EditableMediaDescriptionLabel": "Edit Media Description",
  "Beta": "BETA",
  "Record": "Launch Capture",
  "RecordInProgressBody": "When you're done recording, your video will be uploaded to your library. If you leave this page you will find your video in your library once the upload is complete.",
  "RecordInProgressTitle": "Recording in Progress",
  "RecordLaunchNow": "Launch Now",
  "RecordLaunchHelp": "We are attempting to launch TechSmith Capture. If you do not already have it installed, click the download button below.",
  "RecordLaunchLearnMore": "<a target=\"_blank\" rel=\"noopener noreferrer nofollow\" href=\"https://www.techsmith.com/tutorial-techsmith-relay-record-video.html\">Learn More</a> about TechSmith Capture.",
  "RecordLaunchPageTitle": "Launching TechSmith Capture",
  "RecordLaunchMain": "Launching TechSmith Capture ({0}s)",
  "ApplicationFullNameLogo": "{0} Logo",
  "RecordLaunchToast": "Launching TechSmith Capture...",
  "Description": "Description:",
  "DescriptionWithoutColon": "Description",
  "EditDetails": "Edit Details",
  "SelectFolderPlaceHolderText": "Select Location...",
  "Save": "Save",
  "DescriptionMaxLengthError": "Description is limited to {0} characters.",
  "TitleMaxLengthError": "Title is limited to {0} characters.",
  "TitlePlaceholder": "Add title ({0} character limit)",
  "TitleRequiredError": "Title name cannot be empty.",
  "MediaDetailsSaved": "Details saved successfully!",
  "Saving": "Saving...",
  "PrivacyTab_Public_AdminOnly": "Only people with the link (administrators only)",
  "PrivacyTab_OnlyYou_EnterpriseDescription": "Only you and site admins can view this media.",
  "PrivacyTab_Organization_Description": "People from {0} can find and access. Visible in {1} search results.",
  "PrivacyTab_Organization_WithLink_Description": "People from {0} with the direct link can access. Hidden from {1} search results.",
  "PrivacyTab_Public_EnterpriseDescription": "Anyone on the internet with a link can view. Hidden from public search engines. Visible in {0} search results.",
  "ClassicRecorderDownload": "Download Classic Recorder",
  "ClassicRecorderLaunch": "Launch Classic Recorder",
  "Group_AddMember_NonexistentUser": "User not found. Unable to add member.",
  "DescriptionDisabledMessage": "Description cannot be edited while your media is processing.",
  "TitleWithColon": "Title:",
  "DoNotShowThisAgain": "Do not show this again",
  "GradeReporting": "Grade Reporting",
  "GradeReportingEnabled": "Grade Reporting Enabled",
  "LtiBrightspaceGradePrompt": "Would you like {0} to report scores to the Brightspace gradebook for this media?",
  "LtiBrightspaceGradeReminder1": "For the best experience, create a Brightspace gradebook item and attach it to this content before sharing with students.",
  "No": "No",
  "Yes": "Yes",
  "LtiBrightspaceGradeReminder2": "Otherwise, a gradebook item will be automatically created after the first student views this media.",
  "MediaBeingProcessedCancelHelp": "Click '{0}' to end processing and restore this video to its previous version.",
  "ErrorCancellingEncodeJob": "Whoops, something went wrong. Please try again.",
  "CaptioningActions_AddAssignee": "Add Assignee",
  "CaptioningActions_EditCaptions": "Edit Captions",
  "CaptioningActions_GenerateCaptions": "Generate Captions",
  "CaptioningActions_RequestThirdParty": "Request 3rd Party Captions",
  "CaptioningActions_SelectAnOption": "Select an Action",
  "CaptioningActions_Label": "CaptionOptions",
  "Captioning_ThirdPartyConfirmWidget_Body": "Third party captioning requires approval from an admin.  Click submit to send this request to your admin's approval queue.",
  "Captioning_ThirdPartyConfirmWidget_Title": "Confirm 3rd Party Captioning",
  "PrivacyTab_Organization_AdminOnly": "Only people at {0} (administrators only)",
  "RecordRelaunch": "Relaunch",
  "CloseTab": "Close This Tab",
  "ViewNow": "View Now",
  "Captioning_Transcription_ThirdPartyApprovalInProgressMessage": "Awaiting approval for 3rd party captioning.  Visit your <a href=\"{0}\">Captioning Activity</a> to see the status.",
  "Captioning_Transcription_ThirdPartyCancelButtonText": "Cancel 3rd Party Request",
  "Captioning_Transcription_ThirdPartyTranscriptionInProgressMessage": "Your 3rd party captioning request has been approved and sent to be captioned.  Visit <a href=\"{0}\">Captioning Activity</a> to see the status.",
  "Activity_Captioning_Filter_PendingApproval": "Awaiting Approval",
  "Captioning_CancelTranscriptionConfirmWidget_Title": "Cancel Automatic Transcription",
  "Captioning_CancelTranscriptionConfirmWidget_Body": "Are you sure you want to cancel automatic transcription?",
  "Captioning_CancelConfirmButton": "Yes, Cancel",
  "Captioning_ThirdPartyCancelConfirmWidget_Body": "Are you sure you want to cancel this 3rd party request?",
  "Captioning_ThirdPartyCancelConfirmWidget_Title": "Cancel 3rd Party Captioning",
  "Captioning_ThirdPartyDenyWidget_Title": "Deny Request",
  "Approved": "Approved",
  "Denied": "Denied",
  "Deny": "Deny",
  "Captioning_ThirdPartyDenyWidget_Placeholder": "(Optional) Send a message to video requester...",
  "Captioning_ThirdPartyDenied_DefaultReason": "(no reason given)",
  "ChangePermission_Bullet2": "Any 3rd party captioning request will be cancelled.",
  "Captioning_Transcription_Privacy_Failure": "You cannot submit private videos for 3rd party captioning.",
  "CaptioningActions_ManageAudioDescription": "Manage Audio Description",
  "AudioDescription_Manage_Title": "Manage Audio Description",
  "AudioDescription_Upload_HelpLink": "<a href=\"{0}\" target=\"_blank\" rel=\"noopener noreferrer nofollow\" >Learn More</a>",
  "AudioDescription_Upload_Instructions": "Select an audio file from your computer to add an audio description track",
  "AudioDescription_Upload_FileTypes": "Only .mp3, and .m4a files",
  "AudioDescription_Upload_DropFileToBegin": "Drop file to begin upload",
  "AudioDescription_Upload_GeneralError": "There was an error uploading this file.",
  "AudioDescription_Upload_Invalid_File_Type": "This file does not meet audio requirements.",
  "AudioDescription_Upload_Requirements": "<a href=\"{0}\" target=\"_blank\" rel=\"noopener noreferrer nofollow\" >View Audio Description Media Requirements</a>",
  "AudioDescription_Upload_ValidationError": "This file is not valid. Violations [{0}].",
  "Upload_FileTypes": "Only .mp4, .jpg, .png, .gif files",
  "Accessibility_HelpText": "Use this tab to manage your captions and the audio description track. Learn more about <a href= \"{0}\" target=\"_blank\" rel=\"noopener noreferrer nofollow\" >Captioning in {1}.</a>",
  "Accessibility_Tab": "Accessibility",
  "EditingTab_Note": "Note: Republishing the original will remove captions, quizzes, comments, and the audio description track.",
  "Captioning_ThirdPartyApproved": "3rd Party Processing",
  "AudioDescription_Upload_Title": "Upload Audio Description",
  "Replace": "Replace",
  "AudioDescription_Delete_Message": "Are you sure? The audio description track will no longer be available to any users.",
  "AudioDescription_Delete_Title": "Remove Audio Description",
  "AudioDescription_Removed": "Audio description removed.",
  "AudioDescription_Remove_Error": "Whoops! We had a problem removing the audio description.",
  "AudioDescription_Removing": "Removing audio description.",
  "AudioDescription_Manage_Message": "This video has an existing audio description track. What would you like to do?",
  "AudioDescription_Manage_Options_Label": "Audio Description Options",
  "SelectAnAction": "Select an Action",
  "Captioning_ThirdPartyDenyMultipleWidget_Title": "Deny Requests",
  "Captioning_ThirdPartyDenyMultipleWidget_Note": "Would you like to deny <b>{0}</b> videos for 3rd party captioning?",
  "Captioning_ThirdPartyApproveWidget_Body": "{0} videos will be sent to your 3rd party captioning service.",
  "Captioning_ThirdPartyApproveWidget_Title": "Are you sure?",
  "Approve": "Approve",
  "AccessibiltyAdminDescription": "Can manage assignees and accessibility features (captioning, audio description tracks, etc.) for all videos not set to private.",
  "Captioning_ThirdPartyDisabled_HelpTextPrivate": "3rd Party Captioning is disabled while media is set to private.",
  "Captioning_GenerateDisabled_HelpText": "Generate Captions is disabled because 3rd Party Captions have already been published on this media.",
  "Captioning_ThirdPartyDisabled_HelpTextThirdPartyRepeat": "3rd Party Captioning is disabled because 3rd Party Captions have already been published on this media.",
  "AssignUserRolesSummaryTitle": "Change Log",
  "AssignUserRolesSummarySuccess": "{0} of {1} users have been successfully updated.",
  "AssignUserRolesSummaryFailures": "{0} users were not found in the system.",
  "EnterEmailAddress": "Enter Email Address",
  "MediaDetailsSaveError": "An error occurred, please try again.",
  "Accessibility": "Accessibility",
  "EditMedia": "Edit Media",
  "More": "More",
  "MyAccount": "My Account",
  "ShowLess": "Show Less",
  "ShowMore": "Show More",
  "SignOut": "Sign Out",
  "MediaCopiedToYourLibrary": "Media copied to your library",
  "AddConversation": "Add Conversation",
  "AddConversation_Description": "Add a new conversation or select an existing conversation.",
  "ConversationApiErrorBody": "Unable to add a conversation at this time. Check your connection and try again.",
  "ConversationApiErrorTitle": "Unable to add a conversation",
  "ConversationError": "Unable to add conversation. Please check your connection and try again.",
  "ConversationNameInvalidLength": "Please provide a conversation name using 1-100 characters.",
  "EnterConversationName": "Enter Conversation Name",
  "Name": "Name:",
  "NewConversation": "New Conversation",
  "SelectConversation": "Select Conversation:",
  "ConnectionEstablished": "Connection Established",
  "PermissionRequiredToEstablishSession": "This browser requires permission to establish a session.",
  "VisitTheSupportPageForMoreOptions": "visit the support page for more options",
  "RefreshToContinueCheckCookies": "Refresh this page to continue to {0}. If the problem persists, check your cookies settings or",
  "PrivacyTab_OnlyYou_SingleUserDescription": "Nobody else can view this media.",
  "PrivacyTab_Public_SingleUserDescription": "Anyone with a link can view. Hidden from search engines.",
  "ScrollLeft": "Scroll Left",
  "ScrollRight": "Scroll Right",
  "Settings": "Settings",
  "WhatsNew": "What's New",
  "Conversations_Tab": "Conversations",
  "ConversationsTab_EmptyMessage": "Create conversations to discuss your media with other {0} users.  You can have multiple unique conversations attached to a single piece of media.  Create a conversation and then use the menu icon to get a shareable link.  Learn more about <a href=\"{1}\" target=\"_blank\" rel=\"noopener noreferrer nofollow\">Conversations in {0}</a>.",
  "ConversationCreateModal_NamePlaceholder": "Conversation Name",
  "ConversationCreateModal_Text": "Give this conversation a descriptive name.",
  "ConversationCreateModal_Title": "Create Conversation",
  "ConversationsTab_CreateConversation": "Create Conversation",
  "ConversationTile_Commenters": "Commenters",
  "ConversationTile_Comments": "Comments",
  "ConversationTile_CreatedOn": "Created On",
  "ServiceInterruption": "Service interruption",
  "ConversationsTab_ContextMenu_ClearComments": "Clear Comments",
  "ConversationsTab_ContextMenu_DownloadComments": "Download Comments",
  "ConversationRenameModal_Title": "Rename Conversation",
  "ConversationGetLinkModal_Text": "Copy and paste this link to share this conversation.",
  "ConversationGetLinkModal_Title": "Here's the Link",
  "ConversationClearModal_Text": "All comments in this conversation will be deleted.",
  "ConversationClearModal_Title": "Clear Comments",
  "ConversationDeleteModal_Text": "This conversation and all associated comments will be deleted.",
  "ConversationDeleteModal_Title": "Delete Conversation",
  "ConversationModal_DownloadCommentsButtonText": "Download Comments",
  "ConversationModal_OptionalDownloadCommentsText": "(Optional: Select Download Comments to save a local copy before deleting.)",
  "ConversationClearModal_ClearButtonText": "Clear Comments",
  "EditingTab_Note_WithoutComments": "Note: Republishing the original will remove captions, quizzes, and the audio description track",
  "ResetData_Body_2": "Optional: Click Download Data to save a copy of the data before resetting.",
  "Activity": "Activity",
  "ActivityMenu_3rdPartyTab": "3rd Party Captioning",
  "Captioning": "Captioning Activity",
  "CloseMenu": "Close Menu",
  "Feedback": "Feedback",
  "ToggleMenu": "Toggle Menu",
  "ContactTechSmith": "Contact TechSmith",
  "UsersAndRoles": "Users & Roles",
  "AddConversation_Title": "Add Conversation",
  "AddConversation_Help_Title": "Learn more about conversations",
  "TosPrompt_Accept": "Accept",
  "TosPrompt_Decline": "Decline",
  "TosPrompt_TosLabel": "I agree to the {0} <a href=\"{1}\" target=\"_blank\" rel=\"noopener noreferrer nofollow\">Terms of Service</a> and <a href=\"{2}\" target=\"_blank\" rel=\"noopener noreferrer nofollow\">Terms of Use</a>.",
  "TosPrompt_Welcome": "Welcome to {0}",
  "BuyNow": "Buy Now",
  "StartTrial": "Start Trial",
  "StartYour30DayTrial": "Start Your Free 30‑day Trial",
  "LearnMore": "Learn More",
  "TrialHasEnded": "Your Trial has Ended",
  "YourContentWillBeDeleted": "Your content will be deleted on {0:MMMM dd, yyyy}.",
  "SuccessfullyDeletedContent": "Successfully deleted all content",
  "DeleteMyContentButton": "Delete my Content",
  "PermanentlyDeleteContent": "Permanently Delete Content",
  "PermanentlyDeleteContentCheckbox": "Yes, delete my {0} content, including analytics, quizzes, and other associated data.\nYour <a href=\"{1}\" rel=\"noopener noreferrer nofollow\" target=\"_blank\">TechSmith Account</a> will remain active.",
  "ResetData_Title": "Are you sure you want to reset the data?",
  "Connect": "Connect",
  "LtiAccountSetupMainMessage": "Connect {0} to Your Account",
  "LtiAccountSetupDetailsMessage": "Please verify your email address to connect your accounts.",
  "LtiAccountSetupDetailsMessageWithEmail": "Please verify <strong>{0}</strong> to connect your accounts.",
  "LtiAccountSetupFooterMessage": "Contact your administrator if you have any questions.",
  "LtiAccountSetupEnterEmailAddressMessage": "Please verify your email address to connect your accounts.",
  "LtiAccountSetupEnterEmailAddress": "Enter Your Email Address",
  "LtiAccountSetupWaitingForComplete": "Complete Your Account Setup",
  "LtiAccountSetupWrongEmailAddress": "Wrong email address?",
  "LtiAccountSetupEmailSentMessage": "We just emailed <strong>{0}</strong>, please check your inbox and follow the instructions to continue.",
  "Success": "Success",
  "LTI_SigninComplete_Body": "Your accounts are connected.  Close this tab to return to your course.",
  "LtiAccountSetupConnectError": "Failed to connect account. Contact your administrator.",
  "LtiAccountSetupInvalidEmail": "Invalid email address. Do not include the @yourschool.edu in the email field.",
  "Success_Bang": "Success!",
  "LtiAccountConfirmAccountOwnership": "Confirm Account Ownership",
  "LtiAccountConfirmBody": "Do the following email addresses belong to you?",
  "LtiAccountConfirmButton": "Yes, these are both mine",
  "LtiAccountConfirmAdminButton": "Connect {0} Admin Account",
  "LtiAccountCancelButton": "No, one or both of these are not mine",
  "LtiAccountConnectAdminAccount": "Connect to {0} Admin Account?",
  "LtiAccountConnectAdminAccountBody": "LMS user <strong>{0}</strong> will have access to {1} Admin account associated with <strong>{2}</strong>.",
  "LtiAccountConnectAdminAccountSubText": "If you are acting on behalf of a user who is not associated with <strong>{0}</strong>, you should not connect accounts.",
  "LtiAccountCancelConnectionMessage": "\n    <p>\n       <strong>Check the following:</strong></br>\n       <ul>\n       <li>You are signed in to your own {0} account</li>\n       <li>You are signed in to your own LMS account</li>\n       <li>You are not acting as another user</li>\n       </ul>\n    </p>\n    <p>\n       <strong>Acting as Another User</strong></br>\n       If you are acting on behalf of another user, the owner of <strong>{1}</strong> must log into their LMS course and complete this process before you can proceed.\n    </p>\n    <p>\n    Please contact your site administrator for further assistance.\n    </p>\n    <p>\n    This tab can be closed.\n    </p>\n    ",
  "LtiAccountSetupAwaitingLogin": "Please complete your account setup in the new tab.",
  "ConversationApiGenericErrorBody": "Conversation request failed. Check your connection and try again.",
  "ConversationApiGenericErrorTitle": "Request failed",
  "ZoomSuccessMessage": "Zoom has been added to your site at: {0}",
  "ZoomMoreDetailMessage": "Changes to Zoom can be made in the Zoom Marketplace.",
  "TabCanBeClosed": "This tab can be closed.",
  "ZoomNotAdministratorHelpText": "Contact your site administrator for assistance.",
  "ZoomNotAdministratorMessage": "You must be an administrator of {0} in order to complete the Zoom App installation.",
  "MyFeed": "My Feed",
  "DefaultOrganizationName": "your organization",
  "PrivacyTab_OnlyYou_Enterprise": "Only you and admins",
  "SubscriptionHasEnded": "Your Subscription has Ended",
  "ManageCollection": "Manage Collection",
  "CollectionUpdatedMessage": "Updated {0}",
  "CreateSubfolder": "Create Subfolder",
  "CreateFolder": "Create folder",
  "Manage": "Manage",
  "CreateAndShareEngagingContent": "Create and share engaging content",
  "MakeYourContentAccessible": "Make your content accessible",
  "ShareVideoContentAndDiscuss": "Share video content and discuss it with students and peers",
  "UseInVideoQuizzing": "Use in-video quizzing to conduct formative assessments",
  "LookingForOrganizationalAccount": "Looking for your {0} organizational account? ",
  "GetHelpHere": "Get help here.",
  "CanvasRteGradeWarning": "{0} content added in the rich text editor will not send quiz results or data for course grading. To add media for grading, <a href=\"https://www.techsmith.com/tutorial-knowmia-adding-media-to-canvas.html\" target=\"_blank\" rel=\"noopener noreferrer nofollow\" >see this tutorial.</a>",
  "ErrorLeavingCollection": "There was an error leaving the collection",
  "LeavingCollection": "Leaving collection...",
  "PrivateMediaPlaceholder": "[private media]",
  "MoveContentCollectionFailure": "Failed to add content",
  "MoveContentCollectionSuccess": "Content added successfully!",
  "MovingContentCollection": "Adding Content to {0}",
  "ClosedCaptioning": "Closed captioning",
  "CaptionInsights": "Caption Insights",
  "CaptionedVideos": "Captioned Videos",
  "PercentageCaptioned": "Percentage Captioned",
  "UncaptionedVideos": "Uncaptioned Videos",
  "ExcludePrivateMedia": "Exclude Private Media",
  "LoadingInsights": "Loading Insights ...",
  "Draft": "Draft",
  "PendingApproval": "Pending Approval",
  "ReadyForEdit": "Ready For Edit",
  "Transcribing": "Transcribing",
  "Activity_Captioning_Filter_All": "All",
  "NotStarted": "Not Started",
  "Published": "Published",
  "AllVideos": "All Videos",
  "AssignedToMe": "Assigned to Me",
  "MyVideos": "My Videos",
  "AddNewCaptionTrackTitle": "Add New Caption Track",
  "AddNewCaptionTrackTranscriptionOption": "Speech-to-text",
  "AddNewCaptionTrackTranscriptionOptionDescription": "Automatically generate captions for this video.",
  "AddNewCaptionTrackCustomOption": "Custom",
  "AddNewCaptionTrackCustomOptionDescription": "Add captions manually with the Caption Editor.",
  "AddNewCaptionTrackThirdPartyOption": "Third-Party",
  "AddNewCaptionTrackThirdPartyOptionDescription": "Request generated captions from a third party captioning service.",
  "CaptionsSuccessfullyPublished": "Your captions have been successfully published.",
  "CaptioningActions_BatchGenerateCaptions_Body": "Would you like to generate speech-to-text captions for the {0} selected videos?",
  "CaptioningActions_SingleGenerateCaptions_Body": "Would you like to generate speech-to-text captions for the selected video?",
  "Generate": "Generate",
  "Captions_GenerateCaptions_Success": "Successfully started speech-to-text caption generation for the selected videos",
  "Captions_GenerateCaptions_Error": "Whoops, something went wrong while requesting speech-to-text captions",
  "ErrorChangeOwnerMediaProcessing_Text": "You cannot change the owner until media in the folder has finished processing. Please try again.",
  "ErrorChangeOwnerMediaProcessing_Title": "Unable to Change Owner",
  "ContinueToKnowmiaPro": "Continue to Knowmia (Pro)",
  "NotRightLocationSingular": "Not the right location?",
  "NotRightLocationsPlural": "Not the right locations?",
  "OrganizationAccount": "Organization Account",
  "SignInWithDifferentEmail": "Sign in with different email",
  "VideoCountPlural": "{0} Videos",
  "VideoCountSingular": "1 Video",
  "WeFoundOrganizationSingular": "We found 1 organization associated with your account",
  "WeFoundOrganizationsPlural": "We found {0} organizations associated with your account",
  "WelcomeBack": "Welcome back, {0}",
  "ProEnterpriseSeparator": "Or",
  "Teams": "Teams",
  "Team": "Team",
  "TeamLibrary": "{0} Library",
  "OpenTeam": "Open Team",
  "TeamLibraries": "Team Libraries",
  "MyTeamLibraries": "My Team Libraries",
  "CreateTeamLibrary": "Create New Team Library",
  "UserCollections": "User Collections",
  "UserTeamLibraries": "User Team Libraries",
  "ErrorCreatingTeamLibrary": "Whoops! Something went wrong creating the team library.",
  "CreatingTeamLibrary": "Creating '{0}'...",
  "TeamLibraryNameEmptyError": "Team Library name cannot be empty.",
  "TeamLibraryNameNotString": "Team Library name is not a string.",
  "TeamLibraryNameTooLong": "Team Library names are limited to 70 characters.",
  "CookiesDisabledInTab": "Your browser settings blocked this site.  Open {0} in a new tab to access this content.",
  "LaunchNewTab": "To access this content you must open {0} in a new tab.",
  "LaunchNewTabButtonText": "Open New Tab",
  "LoggedIn_RefreshPage": "Refresh Page",
  "GroupPlaceHolderText": "{0} character max",
  "LaunchLoginHelpLink": "Refresh this page after you have signed in, or <a ng-attr-target=\"{{{{loginLinkTarget}}}}\" href=\"{0}\">sign in</a>",
  "OpeningEllipsis": "Opening...",
  "MissingRequiredField": "One of the required fields for this search document is empty.",
  "MissingRequiredParameter": "Parameter is required and cannot be empty. Parameter: {0}",
  "P3PMessage": "TechSmith does not use P3P policies. Please refer to {0} for TechSmith's Privacy Policy.",
  "SiteIsInReadOnlyMode": "Site is in read-only mode, which is temporarily limiting features.",
  "CollaborationInvitationEmailSubject": "You've Been Invited to Collaborate on a Video",
  "CollaborationInvitationCallToAction": "Accept Invitation",
  "NoAccess": "No Access"
};